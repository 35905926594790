var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('Page-Header',{attrs:{"title":_vm.$t('userPermissions.userPermissions') + ' - ' + _vm.userName,"icon":"mdi-account-details-outline"}}),_c('v-card',{attrs:{"loading":_vm.loading,"flat":"","elevation":"0"}},[_c('v-card-text',[_c('v-form',{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('h2',[_vm._v(" "+_vm._s(_vm.$t("users.ui.alertsPermissions"))+" ")])]),_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-select',{attrs:{"outlined":"","multiple":"","chips":"","small-chips":"","required":"","clearable":"","deletable-chips":"","hide-details":"","items":_vm.positions,"label":_vm.$t('userPermissions.alertPosition'),"item-text":"type","item-value":"value","rules":_vm.listRules},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
                                var item = ref.item;
                                var index = ref.index;
return [(
                                        index === 0 ||
                                            index === 1 ||
                                            index === 2
                                    )?_c('v-chip',[_c('span',[_vm._v(_vm._s(item.type))])]):_vm._e(),(index === 3)?_c('span',{staticClass:"grey--text text-caption"},[_vm._v(" (+"+_vm._s(_vm.userPermissionsDto .alertsPositionTypes.length - 3)+" "+_vm._s(_vm.$t("others"))+") ")]):_vm._e()]}}]),model:{value:(_vm.userPermissionsDto.alertsPositionTypes),callback:function ($$v) {_vm.$set(_vm.userPermissionsDto, "alertsPositionTypes", $$v)},expression:"userPermissionsDto.alertsPositionTypes"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-autocomplete',{attrs:{"items":_vm.departments,"item-value":"departmentGuid","item-text":"departmentName","chips":"","required":"","hide-details":"","outlined":"","label":_vm.$t('userPermissions.alertDepartments'),"clearable":"","deletable-chips":"","multiple":"","rules":_vm.listRules,"small-chips":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
                                var item = ref.item;
                                var index = ref.index;
return [(
                                        index === 0 ||
                                            index === 1 ||
                                            index === 2
                                    )?_c('v-chip',[_c('span',[_vm._v(_vm._s(item.departmentName))])]):_vm._e(),(index === 3)?_c('span',{staticClass:"grey--text text-caption"},[_vm._v(" (+"+_vm._s(_vm.userPermissionsDto.alertsDepartments .length - 3)+" "+_vm._s(_vm.$t("others"))+") ")]):_vm._e()]}}]),model:{value:(_vm.userPermissionsDto.alertsDepartments),callback:function ($$v) {_vm.$set(_vm.userPermissionsDto, "alertsDepartments", $$v)},expression:"userPermissionsDto.alertsDepartments"}},[_c('template',{slot:"prepend-item"},[_c('v-btn',{attrs:{"text":"","block":""},on:{"click":_vm.selectAllAlertsDepartments}},[_vm._v(_vm._s(_vm.$t("selectAll")))])],1)],2)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('h2',[_vm._v(" "+_vm._s(_vm.$t("users.ui.complaintsPermissions"))+" ")])]),_c('v-col',{attrs:{"cols":"12"}},[_c('v-autocomplete',{attrs:{"items":_vm.employees,"item-value":"employeeGuid","item-text":"employeeName","chips":"","hide-details":"","required":"","outlined":"","label":_vm.$t('userPermissions.complaintEmployees'),"clearable":"","rules":_vm.listRules,"deletable-chips":"","multiple":"","small-chips":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
                                var item = ref.item;
                                var index = ref.index;
return [(
                                        index === 0 ||
                                            index === 1 ||
                                            index === 2
                                    )?_c('v-chip',[_c('span',[_vm._v(_vm._s(item.employeeName))])]):_vm._e(),(index === 3)?_c('span',{staticClass:"grey--text text-caption"},[_vm._v(" (+"+_vm._s(_vm.userPermissionsDto.employees .length - 3)+" "+_vm._s(_vm.$t("others"))+") ")]):_vm._e()]}}]),model:{value:(_vm.userPermissionsDto.employees),callback:function ($$v) {_vm.$set(_vm.userPermissionsDto, "employees", $$v)},expression:"userPermissionsDto.employees"}})],1)],1)],1)],1),_c('v-card-actions',[_c('v-btn',{staticClass:"white--text",attrs:{"color":"primary darken-1","min-width":100,"loading":_vm.saveLoading},on:{"click":_vm.savePermissions}},[_c('v-icon',[_vm._v("mdi-content-save-outline")]),_vm._v(" "+_vm._s(_vm.$t("save"))+" ")],1),_c('v-spacer')],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }