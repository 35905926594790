<template>
    <v-container fluid>
        <Page-Header
            :title="$t('userPermissions.userPermissions') + ' - ' + userName"
            icon="mdi-account-details-outline"
        />
        <v-card :loading="loading" flat elevation="0">
            <v-card-text>
                <v-form v-model="valid" lazy-validation ref="form">
                    <v-row>
                        <v-col cols="12"
                            ><h2>
                                {{ $t("users.ui.alertsPermissions") }}
                            </h2></v-col
                        >
                        <v-col cols="12" md="12">
                            <v-select
                                outlined
                                multiple
                                chips
                                small-chips
                                required
                                clearable
                                deletable-chips
                                hide-details
                                :items="positions"
                                v-model="userPermissionsDto.alertsPositionTypes"
                                :label="$t('userPermissions.alertPosition')"
                                item-text="type"
                                item-value="value"
                                :rules="listRules"
                            >
                                <template
                                    v-slot:selection="{
                                        item,
                                        index
                                    }"
                                >
                                    <v-chip
                                        v-if="
                                            index === 0 ||
                                                index === 1 ||
                                                index === 2
                                        "
                                    >
                                        <span>{{ item.type }}</span>
                                    </v-chip>
                                    <span
                                        v-if="index === 3"
                                        class="grey--text text-caption"
                                    >
                                        (+{{
                                            userPermissionsDto
                                                .alertsPositionTypes.length - 3
                                        }}
                                        {{ $t("others") }})
                                    </span>
                                </template>
                            </v-select>
                        </v-col>
                        <v-col cols="12">
                            <v-autocomplete
                                :items="departments"
                                item-value="departmentGuid"
                                item-text="departmentName"
                                chips
                                required
                                hide-details
                                v-model="userPermissionsDto.alertsDepartments"
                                outlined
                                :label="$t('userPermissions.alertDepartments')"
                                clearable
                                deletable-chips
                                multiple
                                :rules="listRules"
                                small-chips
                                ><template slot="prepend-item">
                                    <v-btn
                                        text
                                        block
                                        @click="selectAllAlertsDepartments"
                                        >{{ $t("selectAll") }}</v-btn
                                    >
                                </template>
                                <template
                                    v-slot:selection="{
                                        item,
                                        index
                                    }"
                                >
                                    <v-chip
                                        v-if="
                                            index === 0 ||
                                                index === 1 ||
                                                index === 2
                                        "
                                    >
                                        <span>{{ item.departmentName }}</span>
                                    </v-chip>
                                    <span
                                        v-if="index === 3"
                                        class="grey--text text-caption"
                                    >
                                        (+{{
                                            userPermissionsDto.alertsDepartments
                                                .length - 3
                                        }}
                                        {{ $t("others") }})
                                    </span>
                                </template>
                            </v-autocomplete>
                        </v-col>
                        <v-col cols="12"
                            ><h2>
                                {{ $t("users.ui.complaintsPermissions") }}
                            </h2></v-col
                        >
                        <v-col cols="12">
                            <v-autocomplete
                                :items="employees"
                                item-value="employeeGuid"
                                item-text="employeeName"
                                chips
                                hide-details
                                required
                                v-model="userPermissionsDto.employees"
                                outlined
                                :label="
                                    $t('userPermissions.complaintEmployees')
                                "
                                clearable
                                :rules="listRules"
                                deletable-chips
                                multiple
                                small-chips
                            >
                                <template
                                    v-slot:selection="{
                                        item,
                                        index
                                    }"
                                >
                                    <v-chip
                                        v-if="
                                            index === 0 ||
                                                index === 1 ||
                                                index === 2
                                        "
                                    >
                                        <span>{{ item.employeeName }}</span>
                                    </v-chip>
                                    <span
                                        v-if="index === 3"
                                        class="grey--text text-caption"
                                    >
                                        (+{{
                                            userPermissionsDto.employees
                                                .length - 3
                                        }}
                                        {{ $t("others") }})
                                    </span>
                                </template>
                            </v-autocomplete>
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>
            <v-card-actions>
                <v-btn
                    color="primary darken-1"
                    class="white--text"
                    @click="savePermissions"
                    :min-width="100"
                    :loading="saveLoading"
                >
                    <v-icon>mdi-content-save-outline</v-icon>
                    {{ $t("save") }}
                </v-btn>
                <v-spacer></v-spacer>
            </v-card-actions>
        </v-card>
    </v-container>
</template>
<script>
import PageHeader from "../../components/PageHeader.vue";
import { Positions } from "../../BusinessLogic/Models/EmployeePositions";
import DepartmentsApi from "../../Api/DepartmentsApi";
import EmployeesApi from "../../Api/EmployeesApi";
import AccountsApi from "../../Api/AccountsApi";

export default {
    components: {
        PageHeader
    },
    data() {
        return {
            userName: null,
            departments: [],
            userPermissionsDto: {
                alertsPositionTypes: [],
                alertsDepartments: [],
                employees: [],
                userGuid: this.$route.params.id
            },
            employees: [],
            loading: false,
            saveLoading: false,
            permissionsValid: false,
            valid: true,
            rules: [value => !!value || this.$t("required")],
            listRules: [v => v.length > 0 || this.$t("required")]
        };
    },
    created() {
        this.getDepartments();
        this.getEmployees();
        this.getUserInfo(this.$route.params.id);
    },
    computed: {
        positions() {
            return Positions.map(({ value }) => {
                if (value <= 5)
                    return { type: this.$t("positionTypes." + value), value };
            });
        }
    },
    methods: {
        getEmployees() {
            EmployeesApi.GetHighPositionEmployees().then(response => {
                this.employees = response.data.data;
            });
        },
        getUserInfo(userGuid) {
            try {
                this.loading = true;

                AccountsApi.GetUserPositions(userGuid)
                    .then(response => {
                        if (response.data.status == this.responseStatus.ok) {
                            this.userPermissionsDto.alertsPositionTypes =
                                response.data.data.alertsPositionTypes;
                            this.userPermissionsDto.alertsDepartments =
                                response.data.data.alertsDepartments;
                            this.userPermissionsDto.employees =
                                response.data.data.employees;
                            this.userName = response.data.data.userName;

                            this.$refs.form.resetValidation();
                        }
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            } catch (error) {
                console.log(error);
            }
        },
        getDepartments() {
            DepartmentsApi.GetHighLevelDepartments().then(response => {
                this.departments = response.data.data;
            });
        },
        selectAllAlertsDepartments() {
            try {
                this.userPermissionsDto.alertsDepartments = this.departments.map(
                    department => {
                        return department.departmentGuid;
                    }
                );
            } catch (error) {
                console.log(error);
            }
        },
        savePermissions() {
            try {
                if (!this.$refs.form.validate()) return;

                AccountsApi.SavePermissions(this.userPermissionsDto).then(
                    response => {
                        if (response.data.status == this.responseStatus.ok) {
                            this.$toast.success(
                                this.$t("operationAccomplishedSuccessfully")
                            );
                            this.getUserInfo(this.$route.params.id);
                        } else {
                            this.$toast.error(
                                this.$t("error." + response.data.message)
                            );
                        }
                    }
                );
            } catch (error) {
                console.log(error);
            }
        }
    }
};
</script>
